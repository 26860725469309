import { router } from "@inertiajs/react";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, MessagePayload, onMessage } from "firebase/messaging";
import React, { createContext, ReactNode, useContext, useEffect, useMemo } from "react";

import { ModalContext } from "@/ContextProviders/ModalProvider";

// ------ Firebase global init ------

const firebaseConfig = {
	apiKey: import.meta.env.VITE_FIREBASE_API_KEY ?? "-",
	authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN ?? "-",
	projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID ?? "-",
	storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET ?? "-",
	messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID ?? "-",
	appId: import.meta.env.VITE_FIREBASE_APP_ID ?? "-",
	measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID ?? "-",

	vapidPublicKey: import.meta.env.VITE_FIREBASE_VAPID_PUBLIC_KEY ?? "-",
	localStorageTokenKey: "firebaseToken"
};

let app = null;
let messaging = null;

try {
	app = initializeApp(firebaseConfig);

	if (
		typeof window !== "undefined" &&
		window &&
		typeof window.navigator !== "undefined" &&
		window.navigator
	) {
		messaging = getMessaging(app);
	}
} catch (error) {
	console.error("[🔥] Firebase initialization failed", error);
}

// ------ Firebase Context ------

export interface FirebaseContextType {
	registerForPush: () => void;
	requestToken: () => void;
	token: string | null;
}

export const FirebaseContext = createContext<FirebaseContextType>({
	registerForPush: () => {},
	requestToken: () => {},
	token: null
});

// ------ Firebase Provider ------

export interface FirebaseProviderProps {
	children: ReactNode;
}

export const FirebaseProvider: React.FC<FirebaseProviderProps> = ({ children }) => {
	const [token, setToken] = React.useState<string | null>(null);

	const [openedNewTabSuccessfully, setOpenedNewTabSuccessfully] = React.useState<boolean>(true);
	const { setShowPopupWaitingModal, setShowPopupBlockedModal, openInNewTab } =
		useContext(ModalContext);

	useEffect(() => {
		const handleMessage = (e: MessagePayload) => {
			if (e.data) {
				const { title, body, icon } = e.data;

				const notification = new Notification(title ?? "Neue Nachricht", {
					body: body ?? "",
					icon: icon ?? ""
				});

				notification.onclick = () => {
					parent.focus();

					if (typeof window !== "undefined") {
						window.focus();
					}

					if (e.data?.new_tab_url) {
						openInNewTab(e.data.new_tab_url);
					}
					if (e.data?.url) {
						location.href = e.data.url;
					}
				};
			}
		};

		let unsubscribe = () => {};
		if (messaging) {
			try {
				unsubscribe = onMessage(messaging, handleMessage);
				navigator.serviceWorker.addEventListener("message", messageHandler);
			} catch (error) {
				console.error("[🔥] onMessage registration failed", error);
			}
		}

		return () => {
			if (messaging) {
				try {
					unsubscribe();
					navigator.serviceWorker.removeEventListener("message", messageHandler);
				} catch (error) {
					console.error("[🔥] onMessage unsubscribe failed", error);
				}
			}
		};
	}, []);

	const requestToken = async () => {
		if (!messaging) return;

		const newToken = await getToken(messaging, {
			vapidKey: firebaseConfig.vapidPublicKey
		});
		updateToken(newToken);
	};

	const registerForPush = async () => {
		if (!messaging) return;

		try {
			switch (Notification.permission) {
				case "denied":
					console.warn("[🔥] notification permission denied");
					setShowPopupBlockedModal(true);
					return;
				case "granted": {
					requestToken();
					return;
				}
				default:
					setShowPopupWaitingModal(true);
					if ((await Notification.requestPermission()) === "granted") {
						requestToken();
					}
					setShowPopupWaitingModal(false);
					return;
			}
		} catch (error) {
			console.error("[🔥] registering for push", error);
			return;
		}
	};

	const updateToken = async (newToken: string | null) => {
		console.log("[🔥] Token:", newToken);
		if (newToken === null) {
			localStorage.removeItem(firebaseConfig.localStorageTokenKey);
			setToken(null);
		} else {
			const storedToken = localStorage.getItem(firebaseConfig.localStorageTokenKey) ?? "";
			if (newToken && newToken !== storedToken) {
				localStorage.setItem(firebaseConfig.localStorageTokenKey, newToken);
				setToken(newToken);

				await axios.post("/api/device/register", {
					token: newToken,
					type: "web"
				});
			}
		}
	};

	const messageHandler = (event: MessageEvent) => {
		const { data } = event;

		switch (data.action) {
			case "openNewTab":
				if (openInNewTab(data.url)) {
					setOpenedNewTabSuccessfully(true);
				}
				break;
			case "reload":
				if (openedNewTabSuccessfully) {
					router.reload();
				}
				break;
			default:
				if (data.data?.new_tab_url) {
					if (openInNewTab(data.data.new_tab_url)) {
						setOpenedNewTabSuccessfully(true);
					}
				}

				if (data.data?.url && typeof location !== "undefined") {
					location.href = data.data.url;
				}
				break;
		}
	};

	const cachedParams = useMemo(
		() => ({
			registerForPush,
			requestToken,
			token
		}),
		[registerForPush, requestToken, token]
	);

	return <FirebaseContext.Provider value={cachedParams}>{children}</FirebaseContext.Provider>;
};
